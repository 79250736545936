import { useStaticQuery, graphql } from "gatsby"

export const useAsmetVideos = () => {
  const data = useStaticQuery(
    graphql`
      query VideosQuery {
        allStrapiMediaVideo(sort: { order: DESC, fields: publicado_en }) {
          edges {
            node {
              strapiId
              slug
              titulo
              meta {
                og_titulo
                og_imagen {
                  publicURL
                }
                og_descripcion
              }
              link
              descripcion
              publicado_en
              tipo
              blog_subcategorias {
                id
                slug
                nombre
              }
            }
          }
        }
      }
    `
  )
  return data.allStrapiMediaVideo.edges
}
