import React, { useState } from "react"
import { graphql } from "gatsby"

// Layout
import Layout from "../components/layout"
// Components
import Menu from "../components/home/menu"
import Breadcrumb from "../components/elements/breadcrumb"
import VideoSingle from "../components/elements/video-single"
import VideoSidebar from "../components/elements/video-sidebar"
// Hooks
import { useAsmetHome } from "../hooks/use-asmet-home"
import { useAsmetVideos } from "../hooks/use-asmet-videos"

const AsmetVideoTemplate = ({ data }) => {
  const allVideos = useAsmetVideos()
  let flattenVideos = [];
  allVideos.forEach(el => {
    flattenVideos = flattenVideos.concat(Object.values(el))
  });
  const singleVideo = data.asmetVideo
  // _.remove(allVideos, item => item.node.strapiId === singleVideo.strapiId)

  const asmetHome = useAsmetHome()

  const crumbs = [
    { path: `/media`, label: "Media" },
    { path: `/videos`, label: "Videos" },
    { path: `/videos/${singleVideo.slug}`, label: singleVideo.titulo },
  ]

  const [loaded, setLoaded] = useState(false)

  return (
    <Layout
      meta={
        singleVideo.meta
          ? { ...singleVideo.meta, og_tipo: "video" }
          : {
              og_tipo: "video",
              og_titulo: singleVideo.titulo,
              og_descripcion: singleVideo.descripcion || null,
            }
      }
    >
      <div id="asm-template-header" className="contanier-fluid pt-3 pb-3">
        <div className="container">
          <div className="row">
            <div className="col">
              <Breadcrumb crumbs={crumbs} />
              <h3 className="text-white-alpha text-left pt-4">{singleVideo.titulo}</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="asm-template-video"
        className={"asm-media-main container my-5 py-5 " + (loaded ? "asm-element-loaded" : "") }
        onLoad={() => setLoaded(true)}
      >
        <div className="row">
          <div className="asm-media-single col-12 col-md-7">
            <VideoSingle singleVideo={singleVideo} />
          </div>
          <div className="asm-media-sidebar col-12 col-md-5">
            <VideoSidebar singleVideoID={singleVideo.strapiId} sidebarVideos={flattenVideos} />
          </div>
        </div>
      </div>
      <hr />
      <Menu menuSecundario={asmetHome.menu_secundario} />
    </Layout>
  )
}

export default AsmetVideoTemplate

export const query = graphql`
  query AsmetVideoQuery($id: String!) {
    asmetVideo: strapiMediaVideo(strapiId: { eq: $id }) {
      strapiId
      slug
      titulo
      meta {
        og_titulo
        og_imagen {
          publicURL
        }
        og_descripcion
      }
      link
      descripcion
      publicado_en
      tipo
      blog_subcategorias {
        id
        slug
        nombre
      }
    }
  }
`
